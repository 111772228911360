import React from "react";
// reactstrap components
import {
  Input,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Label,
  Table,
} from "reactstrap";
const AllBrand = ({ allbrand, updatecategory, status, deletecat }) => {
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Brand Name</th>
            <th scope="col">Brand Featured Image</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-right">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {allbrand?.map((item, index) => (
            <tr key={item._id}>
              <th scope="row">{index + 1}</th>
              <th scope="row">
                <Media className="align-items-center">
                  <img src={item.icon} style={{ borderRadius: 0, height: 40, marginRight: 10 }} alt="icon" />
                  <Media>
                    <span className="mb-0 text-sm">{item.name}</span>
                  </Media>
                </Media>
              </th>
              <th scope="row">
                <Media className="align-items-center">
                  <img src={item.image} style={{ borderRadius: 0, height: 40, marginRight: 10 }} alt="icon" />
                </Media>
              </th>
              <td>
                <Label className="custom-toggle custom-toggle-warning">
                  <Input onClick={(e) => status(e, item)} defaultChecked={item.status} type="checkbox" />
                  <span className="custom-toggle-slider rounded-circle" data-label-off="Off" data-label-on="On" />
                </Label>
              </td>

              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem onClick={(e) => updatecategory(item)}>
                      <i className="fas fa-edit text-primary" /> Edit
                    </DropdownItem>
                    <DropdownItem href="#pablo" onClick={(e) => deletecat(e, item._id)}>
                      <i className="fas fa-trash text-danger" />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AllBrand;
