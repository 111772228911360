import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Table,
  Button,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import { Popconfirm } from "antd";

function AllRankRequest() {
  const [AllRank, setAllRank] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");

  // getAllRankRequest
  const getAllRankRequest = useCallback(async (e) => {
    try {
      setReady(true);
      const { data } = await Dataservices.getAllRankrequest();
      setAllRank(data?.data);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getAllRankRequest();
    return () => {
      getAllRankRequest();
    };
  }, [getAllRankRequest]);

  // article Status
  const VerifyReq = async (distributor_id) => {
    try {
      const Data = {
        distributor_id,
      };
      const { data } = await Dataservices.UpgradeRankrequest(
        queryString.stringify(Data)
      );
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">All Rank Request</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        id="title"
                        placeholder="Search Rank"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Code </th>
                        <th scope="col">Requested for</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllRank?.map((item, index) => (
                        <tr key={item._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.name}</td>
                          <td>{item?.email}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.code}</td>
                          <td>
                            {item?.rank_request_38 === "memberCheckRequested"
                              ? "Request for checking member regarding rank 38 upgrade"
                              : item?.rank_request_38 === "salesCheckRequested"
                              ? "Request for checking sales regarding rank 38 upgrade"
                              : item?.rank_request_41 === "memberCheckRequested"
                              ? "Request for checking member regarding rank 41 upgrade"
                              : item?.rank_request_41 === "salesCheckRequested"
                              ? "Request for checking sales regarding rank 41 upgrade"
                              : item?.rank_request_42 === "memberCheckRequested"
                              ? "Request for checking member regarding rank 42 upgrade"
                              : "Request for checking sales regarding rank 42 upgrade"}
                          </td>
                          <td>
                            <Popconfirm
                              title="Are you sure to approved this request?"
                              onConfirm={() => VerifyReq(item?._id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button color="success" size="sm">
                                Accept
                              </Button>
                            </Popconfirm>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllRankRequest;
