import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Button,
} from "reactstrap";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
// APi
import queryString from "query-string";
import Dataservices from "../../services/requestApi";

// core components
import Header from "components/Headers/Header";
import SearchViaDate from "components/Filter/SearchViaDate";
import { Table } from "antd";
function AllOrder() {
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [AllOrder, setAllOrder] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal]= useState();

  const history = useHistory();
  useEffect(() => {
    GetAllOrder(fromDate, toDate, currentPage, pageSize, search);
    return () => {
      GetAllOrder(fromDate, toDate, currentPage, pageSize, search);
    };
  }, [search]);
  // GetAllOrder
  const GetAllOrder = async (from, end, currentPage, pageSize, search) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllOrder(from, end, currentPage, pageSize, search);
      setAllOrder(res.data?.data);
      setTotal(res.data?.total);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  };

  const updateProudctOrder = async (id, data) => {
    try {
      await Dataservices.updateProudctOrder(id, queryString.stringify(data));
      GetAllOrder(fromDate, toDate, currentPage, pageSize, search);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteOrder = async (id) => {
    // try {
    //   await Dataservices.deleteOrder(id);
    //   GetAllOrder();
    // } catch (e) {
    //   console.log(e);
    // }
  };
  // const searchFilter = AllOrder.filter((data) => {
  //   const name = data?.distributor?.name;
  //   const phone = data?.distributor?.phone;
  //   const FilterName = name.toLowerCase().includes(search.toLowerCase());
  //   const FilterPhone = phone.includes(search.toLowerCase());
  //   if (FilterName) {
  //     return FilterName;
  //   }
  //   if (FilterPhone) {
  //     return FilterPhone;
  //   }
  // });
  const SearchDate = async () => {
    if (fromDate && toDate) {
      GetAllOrder(fromDate, toDate, currentPage, pageSize, search);
    }
  };

  const csvData = [
    ["Sr No.", "Name", "Phone", "Created on"],
    ...AllOrder?.map((item, idx) => [
      idx + 1,
      item?.distributor?.name,
      item?.distributor?.phone,
      moment(item?.created_at?.split("T")[0]).format("ll"),
    ]),
  ];
  function InvoiceGenerate(id) {
    const order = AllOrder;
    const CurrentOrder = order.find((item) => item._id === id);
    history.push({ pathname: "/admin/invoice", state: CurrentOrder });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "distributor",
      key: "name",
      render: (item) => item.name,
    },
    {
      title: "Phone",
      dataIndex: "distributor",
      key: "phone",
      render: (item) => item.phone,
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date?.split("T")[0]).format("ll"),
    },
    {
      title: "Status",
      dataIndex: "order_id",
      key: "status",
      render: (id, item) => (
        <FormGroup className="w-75 mb-0">
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            onChange={(e) => {
              updateProudctOrder(item._id, {
                status: e.target.value,
              });
            }}
            defaultValue={item.status}
          >
            <option value="">Update Status</option>
            <option value="In Process">In Process</option>
            <option value="Order Dispatched">Order Dispatched</option>
            <option value="Out for Delivery">Out for Delivery</option>
            <option value="Order Delivered">Order Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </Input>
        </FormGroup>
      ),
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (id, item) => (
        <Button
          onClick={() => InvoiceGenerate(id)}
          size="sm"
          color="dark"
          outline
          to={`orders-transaction/${item?._id}`}
          tag={Link}
        >
          <i className="ni ni-money-coins text-dark mr-2" />
          View Transactions
        </Button>
      ),
    },
    {
      title: "Invoice",
      dataIndex: "_id",
      key: "_id",
      render: (id) => (
        <>
          <Button
            onClick={() => InvoiceGenerate(id)}
            size="sm"
            color="dark"
            outline
          >
            <span className="ni ni-active-40"></span>
          </Button>
        </>
      ),
    },
  ];
  function ExportExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(csvData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.writeFile to trigger the download
    XLSX.writeFile(wb, "Allorder.xlsx");
  }
  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    GetAllOrder(fromDate, toDate, page, pageSize, search);
  };


  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col>
                    <FormGroup className="mb-0">
                      <Label for="toDate" className="d-block">
                        &nbsp;
                      </Label>
                      <Input
                        id="title"
                        placeholder="Search by Phone / Name"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  <SearchViaDate
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    action={SearchDate}
                  />
                </Row>
              </CardHeader>
              <CardBody>
                <CSVLink
                  className="btn btn-outline-dark btn-sm"
                  filename="order.csv"
                  data={csvData}
                >
                  Export CSV
                </CSVLink>
                <Button onClick={ExportExcel} outline size="sm" color="dark">
                  Export Excel
                </Button>
                <Table
                  id="AllOrder"
                  columns={columns}
                  loading={ready}
                  dataSource={AllOrder}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                    onChange: (page, pageSize)=> {
                      handleTableChange(page, pageSize)
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllOrder;
