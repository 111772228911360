import React, { useState, useEffect, useCallback } from "react";
import * as XLSX from "xlsx";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Button,
  ButtonGroup,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
// APi
import queryString from "query-string";
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import { Link } from "react-router-dom";
import moment from "moment";
import SearchViaDate from "components/Filter/SearchViaDate";
import { Pagination, Table, message } from "antd";
function AllDistributor() {
  const [AllUser, setAllUser] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [AllRank, setAllRank] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal]= useState();
  const [password, setPassword] = useState("");
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const verifyPassword = () => {
    // Assuming you have a function to verify the password
    if (password === "Shopers@123") { // Replace with your real check logic
      setIsPasswordVerified(true);
      setIsModalOpen(false);  // Close the modal upon successful verification
    } else {
      alert("Incorrect password");
    }
  };


  const getAllRank = useCallback(async (e) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllRank();
      setAllRank(res?.data?.Admin);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getAllRank();
  }, [getAllRank]);
  // getAllUSERS
  const getAllUSERS = useCallback(async (from, end, currentPage, pageSize, search) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllDistributor(from, end, currentPage, pageSize, search);
      if (res.data?.message === "Success") {
        const user = res.data?.data;
        console.log(res.data?.data);
        const AllDistributor = [];
        for (let [index, val] of user.entries()) {
          // your code goes here
          val.UUID = ((currentPage-1)*10)+ index + 1;
          AllDistributor.push(val);
        }
        setAllUser(AllDistributor);
        setTotal(res.data?.total);
        // setTotalDist(res.data?.totalDistributors);
        // setTotalPage(res.data?.totalPages);
        setReady(false);
      }
    } catch (e) {
      console.log(e);
      setReady(false);
    }
  }, []);
  useEffect(() => {
    getAllUSERS(fromDate, toDate, currentPage, pageSize, search);
  }, [getAllUSERS]);

  const DistributorUpdate = async (e, id, MetaData, type) => {
    e.preventDefault();
    try {
      const Data = {};
      if (type === "rank") {
        Data.distributor_rank_id = MetaData;
      } else {
        Data.status = !MetaData;
      }
      const { data } = await Dataservices.updateDistributor(
        id,
        queryString.stringify(Data)
      );
      if (data) {
        message.success("Distributor updated successfully");
      }
      // window.location.reload(true);
    } catch (e) {
      console.log(e);
    }
  };
  // const searchFilter = AllUser.filter((data) => {
  //   const phone = data?.phone;
  //   const name = data?.name;
  //   const FunctionPhone = phone.toLowerCase().includes(search.toLowerCase());
  //   const FunctionName = name.toLowerCase().includes(search.toLowerCase());
  //   if (FunctionPhone) return FunctionPhone;
  //   if (FunctionName) return FunctionName;
  // });

  const SearchDate = async () => {
    if (fromDate && toDate) {
      getAllUSERS(fromDate, toDate, currentPage, pageSize, search);
    }
    if(search){
      getAllUSERS(fromDate, toDate, currentPage, pageSize, search);
    }
  };

  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    getAllUSERS(fromDate, toDate, page, pageSize, search);
  };

  const columns = [
    {
      title: "Sr no",
      dataIndex: "UUID",
      key: "UUID",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Pan No",
      dataIndex: "pan_no",
      key: "pan_no",
    },
    {
      title: "Addhar Number",
      dataIndex: "aadhar_no",
      key: "aadhar_no",
    },
    {
      title: "Account No",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "IFSC code",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
    },
    {
      title: "Sale",
      dataIndex: "totalSale",
      key: "totalSale",
      render: (item) => Math.round(item),
    },
    {
      title: "TDS",
      dataIndex: "totalSaleTds",
      key: "totalSaleTds",
      render: (item) => Math.round(item),
    },
    {
      title: "Rank",
      dataIndex: "distributor_rank_id",
      key: "distributor_rank_idssss",
      render: (item) => item?.rank,
    },
    {
      title: "Update Rank",
      dataIndex: "distributor_rank_id",
      key: "distributor_rank_id",
      render: (id, data) => (
        <FormGroup>
          <Input
            type="select"
            id="distributor_rank_id"
            value={data?.distributor_rank_id?._id}
            className="form-control-alternative"
            style={{ width: 120 }}
            onChange={(e) =>
              DistributorUpdate(e, data?._id, e.target.value, "rank")
            }
            disabled={!isPasswordVerified} // Disable until password is verified
          >
            {AllRank?.map((item) => (
              <option value={item._id} key={item._id}>
                {item.name}
                {" - "} {item.rank}
              </option>
            ))}
          </Input>
          <Button className="mt-2" color="primary" onClick={toggleModal}>
            Verify Password
          </Button>
        </FormGroup>
      ),
    },

    {
      title: "DOJ",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date?.split("T")[0]).format("ll"),
    },
    {
      title: "Parent Name",
      dataIndex: "parent_id",
      key: "parent_id",
      render: (item) => item?.name ?? "NA",
    },
    {
      title: "Wallet",
      dataIndex: "wallet",
      key: "wallet",
      render: (item) => Math.round(item),
    },
    {
      title: "Genealogy",
      dataIndex: "_id",
      key: "_id",
      render: (id, item) => (
        <Link
          className={`btn btn-outline-success btn-sm`}
          to={{
            pathname: "all_distributor_child",
            state: item,
          }}
        >
          Genealogy
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      render: (id, item) => (
        <Label className="custom-toggle custom-toggle-warning">
          <Input
            onClick={(e) =>
              DistributorUpdate(e, item?._id, item?.status, "status")
            }
            defaultChecked={item?.status}
            type="checkbox"
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="Off"
            data-label-on="On"
          />
        </Label>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "Action_id",
      render: (id, item) => (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              to={`all_distributor_transaction/${item?._id}`}
              tag={Link}
            >
              <i className="ni ni-money-coins text-primary" /> Transactions
            </DropdownItem>
            <DropdownItem to={`all_distributor_rank/${item?._id}`} tag={Link}>
              <i className="ni ni-sound-wave text-danger" /> Rank History
            </DropdownItem>
            <DropdownItem
              to={{
                pathname: `update_distributor/${item?._id}`,
                state: item,
              }}
              tag={Link}
            >
              <i className="ni ni-settings text-danger" /> Update Profile
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const csvData = [
    [
      "Sr No.",
      "Name",
      "Phone",
      "Sale",
      "TDS",
      "Rank",
      "DOJ",
      "Parent Name",
      "Wallet",
    ],
    ...AllUser?.map((item, idx) => [
      idx + 1,
      item?.name,
      item?.phone,
      item?.totalSale,
      item?.totalSaleTds,
      item?.distributor_rank_id?.rank,
      moment(item?.created_at).format("ll"),
      item?.parent_id?.name ?? "NA",
      Math.round(item?.wallet),
    ]),
  ];
  // function OnPageChange(count) {
  //   setCurrentPage(count);
  //   getAllUSERS(fromDate, toDate, pageLimit, count);
  // }
  function ExportExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(csvData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.writeFile to trigger the download
    XLSX.writeFile(wb, "Alldistributor.xlsx");
  }
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow mt-5">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Label for="phoneSearch">
                            Search by phone or name
                          </Label>
                          <Input
                            className="form-control-alternative border shadow"
                            placeholder="Enter phone number or name"
                            id="phoneSearch"
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <SearchViaDate
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        action={SearchDate}
                      />
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CSVLink
                  className="btn btn-outline-dark btn-sm"
                  filename="AllDistributor.csv"
                  data={csvData}
                >
                  Export CSV
                </CSVLink>
                <Button onClick={ExportExcel} outline size="sm" color="dark">
                  Export Excel
                </Button>
                <Table
                  columns={columns}
                  loading={ready}
                  dataSource={AllUser}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                    onChange: (page, pageSize)=> {
                      handleTableChange(page, pageSize)
                    }
                  }}
                />
                {/* {searchFilter.length > 0 && (
                  <Pagination
                    responsive
                    current={currentpage}
                    onChange={OnPageChange}
                    total={totalDist}
                    pageSize={pageLimit}
                    showSizeChanger={false}
                    showTotal={(total) =>
                      `Total Distributor ${totalDist} items`
                    }
                  />
                )} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Verify Password</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={handlePasswordChange}
                className="form-control-alternative"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={verifyPassword}>
            Verify
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

    </>
  );
}

export default AllDistributor;
