import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Input } from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Addpop from "./Addpop";
import AllBanner from "./Allpop";
import Loader from "components/loader/Loader";
import { message } from "antd";

//Firebase

const Popup = () => {
  const [title, settitle] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [image, setImage] = useState(null);
  const [AllPop, setAllPop] = useState([]);
  const [update, setUpdate] = useState(false);
  const [bannerid, setBannerID] = useState("");
  const [ready, setReady] = useState(false);
  const [type, setType] = useState("");
  const [search, setSearch] = useState("");
  const [url, setURL] = useState("");
  // AddLanguage
  const PopupUpdate = async (e) => {
    // {console.log(PopupAdd)}
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    // data.append("type", type);
    data.append("image", image);
    data.append("url", url);
    try {
      setReady(true);
      const res = await Dataservices.PopupUpdate(AllPop[0]._id, data);
      message.success(res.data.message);
      settitle("");
      setImage(null);
      setThumbnail(null);
      setType("");
      setReady(false);
      getPop();
    } catch (e) {
      console.log(e);
    }
  };

  // get popup
  const getPop = useCallback(async () => {
    try {
      const res = await Dataservices.getPop();
      console.log(res.data.data[0]);
      settitle(res.data.data[0].title);
      setURL(res.data.data[0].url);
      setThumbnail(res.data.data[0].Image_link);
      setAllPop(res.data.data);
    } catch (e) {
      console.log(e);
    }
  }, []);
  // Fetch AllBanner
  useEffect(() => {
    getPop();
  }, []);

  // banner Status
  // const bannerUpdateStatus = async (e, data) => {
  //   e.preventDefault();
  //   setUpdate(true);
  //   settitle(data.title);
  //   setType(data.type);
  //   setImage(data.image);
  //   setThumbnail(data.image);
  //   setBannerID(data._id);
  // };
  // Status
  const status = async (e, val) => {
    // e.preventDefault();
    try {
      const data = new FormData();
      data.append("status", !val.status);
      const res = await Dataservices.PopupUpdate(val._id, data);
      if (res.data) {
        message.success(`${val.title} Status updated successfully`);
        getPop();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Banner Updates
  const updatePop = async (e) => {
    e.preventDefault();
    setReady(true);
    try {
      if (image) {
        const data = new FormData();
        data.append("title", title);
        data.append("type", type);
        data.append("image", image);
        const res = await Dataservices.BannerUpdate(bannerid, data);
        if (res.status) {
          message.success("Update Successfully");
          setReady(false);
          settitle("");
          setImage(null);
          setThumbnail(null);
          setType("");
          getPop();
          setUpdate(false);
        }
      } else {
        message.error("Please Upload Image");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Delete banner
  // const deleteBanner = async (id) => {
  //   setReady(true);
  //   try {
  //     await Dataservices.BannerDelete(id);
  //     setReady(false);
  //     message.success("Banner Delete Successfully");
  //     getAllBanner();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const searchFilter = Allbanner.filter((data) => {
  //   return data.title.toLowerCase().includes(search.toLowerCase());
  // });
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="4">
            <Card className="card-profile shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add Pop-UP</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <Col lg="12">
                    <Addpop
                      title={title}
                      settitle={settitle}
                      setimage={setImage}
                      // type={type}
                      setURL={setURL}
                      url={url}
                      updatePop={PopupUpdate}
                      // settype={setType}
                      thumbnail={thumbnail}
                      setthumbnail={setThumbnail}
                      // updatePop={updatePop}
                      // editstatus={update}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">POP-UP</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        id="title"
                        placeholder="Search Banner"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <AllBanner
                  allbanner={AllPop}
                  // bannerupdatestatus={bannerUpdateStatus}
                  // updatebanner={bannerUpdate}
                  // deletebanner={deleteBanner}
                  status={status}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Popup;
