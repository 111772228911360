import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import { Link, useParams } from "react-router-dom";
import { Table } from "antd";
import SearchViaDate from "components/Filter/SearchViaDate";
import moment from "moment";
function AllDistributorTransaction() {
  const [AllTransaction, setAllTransaction] = useState([]);
  const [ready, setReady] = useState(false);
  const { dis_id } = useParams();
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  // getAllTransaction
  const getAllTransaction = async (from, end) => {
    try {
      setReady(true);
      const res = await Dataservices.DistributorTransaction(dis_id, from, end);
      console.log(res.data);
      if (res.data?.message === "Success") {
        const user = res.data?.data;
        setAllTransaction(user);
      }
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAllTransaction();
  }, []);
  const columns = [
    {
      title: "Sr no",
      dataIndex: "name",
      key: "name",
      render: (a, b, idx) => idx + 1,
    },
    {
      title: "Transaction Id",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date?.split("T")[0]).format("ll"),
    },
    {
      title: "Name",
      dataIndex: "order_id",
      key: "Name",
      render: (item) => item?.contact_name,
    },

    {
      title: "Phone",
      dataIndex: "order_id",
      key: "Phone",
      render: (item) => item?.contact_phone,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (item) => <>Rs. {item}</>,
    },
    {
      title: "Mode",
      dataIndex: "transaction_mode",
      key: "Mode",
      render: (item) => (
        <>
          <Badge color={item === "credit" ? "success" : "danger"}>{item}</Badge>
        </>
      ),
    },
  ];
  const SearchDate = async () => {
    if (fromDate && toDate) {
      getAllTransaction(fromDate, toDate);
    }
  };
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="3">
                    <h3 className="mb-0">Distributor Transaction</h3>
                  </Col>
                  <SearchViaDate
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    action={SearchDate}
                  />
                </Row>
              </CardHeader>
              <CardBody>
                <>
                  <Table
                    columns={columns}
                    loading={ready}
                    dataSource={AllTransaction}
                  />
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllDistributorTransaction;
