import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Table,
  Badge,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
function AllDistributorRankHistory() {
  const [AllUser, setAllUser] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const { dis_id } = useParams();
  // getAllTransaction
  const getAllTransaction = useCallback(async (e) => {
    try {
      setReady(true);
      const res = await Dataservices.DistributorRankHistory(dis_id);
      console.log(res.data);
      if (res.data?.message === "Success !") {
        const user = res.data?.Distributor;
        setAllUser(user);
      }
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    getAllTransaction();
  }, [getAllTransaction]);

  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">Distributor Rank History</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        placeholder="Search Distributor"
                        id="title"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Previous Rank </th>
                        <th>New Rank</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllUser &&
                        AllUser.map((item, idx) => (
                          <tr key={item?._id}>
                            <td>{idx + 1}</td>
                            <td>
                              <Badge color={"primary"}>
                                {item?.previous_rank?.rank}
                              </Badge>
                            </td>
                            <td>
                              <Badge color={"primary"}>
                                {item?.new_rank?.rank}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllDistributorRankHistory;
