import { Table } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

// reactstrap components
import { Card, CardHeader, Container, Row, Col, Button } from "reactstrap";

// core components
import Header from "components/Headers/Header";
// APi
import Dataservices from "../../services/requestApi";
import moment from "moment/moment";
function AllDistributorOrder() {
  const [AllOrder, setAllOrder] = useState([]);
  const [ready, setReady] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  // getAllTransaction
  const getAllTransaction = useCallback(async (e) => {
    try {
      setReady(true);
      const res = await Dataservices.getOrderByUser(id);
      if (res.data?.message === "Success") {
        const data = res.data?.data;
        setAllOrder(data);
      }
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    getAllTransaction();
  }, [getAllTransaction]);
  function InvoiceGenerate(id) {
    const order = AllOrder;
    const CurrentOrder = order.find((item) => item._id === id);
    history.push({ pathname: "/admin/invoice", state: CurrentOrder });
  }
  const columns = [
    {
      title: "Sr no",
      dataIndex: "name",
      key: "name",
      render: (a, b, idx) => idx + 1,
    },
    {
      title: "Name",
      dataIndex: "contact_name",
      key: "contact_name",
    },
    {
      title: "Phone",
      dataIndex: "contact_phone",
      key: "contact_phone",
    },
    {
      title: "Total Product",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: `amount`,
      key: "amounts",
      render: (amount) => <>₹ {amount}</>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },

    {
      title: "Order on",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date?.split("T")[0]).format("ll"),
    },
    {
      title: "Invoice",
      dataIndex: "_id",
      key: "_id",
      render: (id) => (
        <>
          <Button
            onClick={() => InvoiceGenerate(id)}
            size="sm"
            color="dark"
            outline
          >
            <span className="ni ni-active-40"></span>
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">
                      All order of {state?.userData?.name}
                    </h3>
                  </Col>
                </Row>
              </CardHeader>

              <Table
                id="Allorder"
                columns={columns}
                loading={ready}
                dataSource={AllOrder}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllDistributorOrder;
