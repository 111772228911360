import React, { useState } from "react";
import * as XLSX from "xlsx";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Tabs } from "antd";
import { CSVLink } from "react-csv";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import Transaction from "components/Transaction/Transaction";
import SearchViaDate from "components/Filter/SearchViaDate";
import moment from "moment";

function AllTransaction() {
  const [AllCreditTransaction, setAllCreditTransaction] = useState([]);
  const [AllDebitTransaction, setAllDebitTransaction] = useState([]);
  const [ready, setReady] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  // GetAllOrder

  const GetAllOrder = async (from, end) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllTransaction(from, end);
      const credit = res.data?.data?.filter(
        (item) => item.transaction_mode === "credit"
      );
      setAllCreditTransaction(credit);
      const debit = res.data?.data?.filter(
        (item) => item.transaction_mode === "debit"
      );
      setAllDebitTransaction(debit);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  };
  const items = [
    {
      key: "1",
      label: `Credit`,
      children: (
        <Transaction transaction={AllCreditTransaction} isLoading={ready} />
      ),
    },
    {
      key: "2",
      label: `Debit`,
      children: (
        <Transaction transaction={AllDebitTransaction} isLoading={ready} />
      ),
    },
  ];
  const SearchDate = async () => {
    if (fromDate && toDate) {
      GetAllOrder(fromDate, toDate);
    }
  };

  const csvData = [
    [
      "Sr No.",
      "Date",
      "Amount",
      "Mode",
      "Channel",
      "Name",
      "Email",
      "Phone",
      "Account Number",
      "Bank Name",
      "Ifsc Code",
    ],
    ...AllCreditTransaction?.map((item, idx) => [
      idx + 1,
      moment(item?.created_at?.split("T")[0]).format("ll"),
      item?.amount,
      item?.transaction_mode,
      item?.channel,
      item?.distributor?.name,
      item?.distributor?.email,
      item?.distributor?.phone,
      item?.distributor?.account_number,
      item?.distributor?.bank_name,
      item?.distributor?.ifsc_code,
    ]),
  ];
  function ExportExcel() {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(csvData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Use XLSX.writeFile to trigger the download
    XLSX.writeFile(wb, "Alldistributor.xlsx");
  }
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <SearchViaDate
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    action={SearchDate}
                  />
                </Row>
              </CardHeader>
              <Card className="shadow">
                <CardBody>
                  <CSVLink
                    className="btn btn-outline-dark btn-sm"
                    filename="AllTransaction.csv"
                    data={csvData}
                  >
                    Export CSV
                  </CSVLink>
                  <Button onClick={ExportExcel} outline size="sm" color="dark">
                    Export Excel
                  </Button>
                  <Tabs defaultActiveKey="1" items={items} />
                </CardBody>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllTransaction;
