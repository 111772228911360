import React, { useState } from "react";
import { FormGroup, Input, Button, Form } from "reactstrap";
import { BASEURL } from "services/http-common";
import Resizer from "react-image-file-resizer";


const Addpop = ({
  title,
  settitle,
  setimage,
  setURL,
  url,
  addpopup,
  updatePop,
  editstatus,
  thumbnail,
  setthumbnail,
}) => {
  const [toggle, setToggle] = useState(false)
  const fileHandle = (e) => {

    const file = e.target.files[0];
    if (file) {
   Resizer.imageFileResizer(
        e.target.files[0],
        600,
        400,
        "JPEG",
        50,
        0,
        (uri) => {
          setToggle(true)
          setimage(uri);
          const showiMG = URL.createObjectURL(file);
          setthumbnail(showiMG);
        },
        "file",
        600,
        300,
        
      );
    }
  };
  return (
    <>
      <Form role="form" onSubmit={updatePop}>
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            Title
          </label>
          <Input
            className="form-control-alternative"
            id="title"
            placeholder="Enter Title"
            type="text"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            URL
          </label>
          <Input
            className="form-control-alternative"
            id=""
            placeholder="Enter Title"
            type="text"
            value={url}
            onChange={(e) => setURL(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="image">
            Image
          </label>
          <Input
            className="form-control-alternative"
            id="image"
            type="file"
            accept="image/*"
            onChange={fileHandle}
            required
          />
        </FormGroup>
        <FormGroup>
          {!toggle&& thumbnail && (
            <img src={`${BASEURL.ENDPOINT_URL}${thumbnail}`} className="img-fluid" alt="popup" />
          )}
          {toggle&& thumbnail && (
            <img src={thumbnail} className="img-fluid" alt="pop-up" />
          )}
        </FormGroup>
        {editstatus ? (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
          >
            Update Banner
          </Button>
        ) : (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
          >
            Add Pop-UP
          </Button>
        )}
      </Form>
    </>
  );
};
export default Addpop;