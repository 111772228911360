import React, { useState, useEffect } from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Input } from "reactstrap";
// core components
import Header from "components/Headers/Header";
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
import AddBrand from "components/Brands/AddBrand";
import AllBrand from "components/Brands/AllBrand";
import Loader from "components/loader/Loader";
const Brands = () => {
  const [brand, setBrand] = useState("");
  const [Allbrand, setAllBrand] = useState([]);
  const [update, setUpdate] = useState(false);
  const [cateid, setCateId] = useState("");
  const [search, setSearch] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIconImage] = useState(null);
  const [iconthumbnail, setIconThumbnail] = useState(null);
  const [image, setImage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [ready, setReady] = useState(false);
  // Fetch AllBrand
  useEffect(() => {
    getAllBrand();
  }, []);
  // GetAllBrand
  const getAllBrand = async (e) => {
    try {
      const resBrand = await Dataservices.BrandAll();
      setAllBrand(resBrand.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  // AddCategory
  const AddBrands = async (e) => {
    e.preventDefault();
    setReady(true);
    let text = brand;
    const slugtext = text.toLocaleLowerCase().replaceAll(" ", "-");
    const form = new FormData();
    form.append("name", brand);
    form.append("slug", slugtext);
    form.append("image", image);
    form.append("icon", icon);
    form.append("description", description);
    try {
      const res = await Dataservices.BrandAdd(form);
      if (res.data) {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Category Status
  const brandUpdateStatus = async (data) => {
    setUpdate(true);
    setBrand(data.name);
    setImage(data?.image);
    setThumbnail(data?.image);
    setIconImage(data?.icon);
    setIconThumbnail(data?.icon);
    setDescription(data?.description);
    setCateId(data._id);
  };

  // Status
  const status = async (e, val) => {
    e.preventDefault();
    try {
      const Data = {
        status: !val.status,
      };
      await Dataservices.BrandUpdate(val._id, queryString.stringify(Data));
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Category Updates
  const brandUpdate = async (e) => {
    e.preventDefault();
    try {
      setReady(true);

      const form = new FormData();
      form.append("name", brand);
      form.append("icon", icon);
      form.append("image", image);
      form.append("description", description);
      const res = await Dataservices.BrandUpdate(cateid, form);
      if (res.data) {
        window.location.reload();
      }
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Category
  const deleteCategory = async (e, id) => {
    e.preventDefault();
    try {
      const res = await Dataservices.BrandDelete(id);
      console.log(res);
      getAllBrand();
    } catch (error) {
      console.log(error);
    }
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const files = URL.createObjectURL(file);
      setThumbnail(files);
    } else {
      setImage(null);
      setThumbnail(null);
    }
  };
  const fileHandlerIcon = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconImage(file);
      const files = URL.createObjectURL(file);
      setIconThumbnail(files);
    } else {
      setIconImage(null);
      setIconThumbnail(null);
    }
  };
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="5">
            <Card className="card-profile shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Add Brand</h3>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <Col lg="12">
                    <AddBrand
                      brand={brand}
                      setBrand={setBrand}
                      description={description}
                      setdescription={setDescription}
                      thumbnail={thumbnail}
                      handleImage={fileHandler}
                      iconthumbnail={iconthumbnail}
                      handleIcon={fileHandlerIcon}
                      addbrand={AddBrands}
                      updatebrand={brandUpdate}
                      statusupdate={update}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="7">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">All Brand</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="shadow"
                        id="title"
                        placeholder="Search Brand"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <AllBrand
                  allbrand={Allbrand}
                  updatecategory={brandUpdateStatus}
                  deletecat={deleteCategory}
                  status={status}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Brands;
