import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Media,
  Table,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
function AllRank() {
  const [AllUser, setAllRank] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");

  // getAllRank
  const getAllRank = useCallback(async (e) => {
    try {
      setReady(true);
      const res = await Dataservices.getAllRank();
      setAllRank(res?.data?.Admin);
      setReady(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getAllRank();
  }, [getAllRank]);

  // article Status

  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">All Rank</h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        id="title"
                        placeholder="Search Rank"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Rank</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllUser?.map((item, index) => (
                        <tr key={item._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.name}</td>
                          <th scope="row">{item?.rank}</th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllRank;
