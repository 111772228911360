import { Col, FormGroup, Label, Input, Button } from "reactstrap";

const SearchViaDate = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  action,
}) => {
  return (
    <>
      <Col>
        <FormGroup className="mb-0">
          <Label for="fromDate">From</Label>
          <Input
            className="form-control-alternative border shadow"
            placeholder="From Date"
            id="fromDate"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-0">
          <Label for="toDate">To</Label>
          <Input
            className="form-control-alternative border shadow"
            placeholder="To Date"
            id="toDate"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-0">
          <Label for="toDate" className="d-block">
            &nbsp;
          </Label>
          <Button color="dark" onClick={action}>
            Search date
          </Button>
        </FormGroup>
      </Col>
    </>
  );
};
export default SearchViaDate;
