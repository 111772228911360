import React from "react";
import { Table } from "antd";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const Transaction = ({ transaction, isLoading }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (item, data, idx) => idx + 1,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date?.split("T")[0]).format("ll"),
    },
    {
      title: "View",
      dataIndex: "distributor",
      key: "goTo",
      render: (item) => (
        <Link
          to={`all_distributor_transaction/${item?._id}`}
          className="text-dark"
        >
          <i className="ni ni-active-40" />
        </Link>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (item) => <>Rs. {item}</>,
    },
    {
      title: "Mode",
      dataIndex: "transaction_mode",
      key: "transaction_mode",
      render: (item) => (
        <>
          <Badge color={item === "credit" ? "success" : "danger"}>{item}</Badge>
        </>
      ),
    },
    {
      title: "Channel",
      dataIndex: `channel`,
      key: "channel",
    },
    {
      title: "Name",
      dataIndex: "distributor",
      key: "Name",
      render: (item) => item.name,
    },
    {
      title: "Email",
      dataIndex: "distributor",
      key: "Email",
      render: (item) => item.email,
    },

    {
      title: "Phone",
      dataIndex: "distributor",
      key: "Phone",
      render: (item) => item.phone,
    },
    {
      title: "Account Number",
      dataIndex: "distributor",
      key: "Account",
      render: (item) => item.account_number,
    },
    {
      title: "Bank Name",
      dataIndex: "distributor",
      key: "Bank",
      render: (item) => item.bank_name,
    },
    {
      title: "Ifsc Code",
      dataIndex: "distributor",
      key: "Ifsc",
      render: (item) => item.ifsc_code,
    },
  ];
  return (
    <Table columns={columns} loading={isLoading} dataSource={transaction} />
  );
};
export default Transaction;
