import TextEditor from "components/TextEditor";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Button, Form } from "reactstrap";
import { BASEURL } from "services/http-common";
import Resizer from "react-image-file-resizer";


const AddArticleNews = ({
                          title,
                          settitle,
                          editstatus,
                          addbanner,
                          updatebanner
}) => {
  const [toggle, setToggle] = useState(false)
  // const fileHandle = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //      Resizer.imageFileResizer(
  //       e.target.files[0],
  //       500,
  //       500,
  //       "JPEG",
  //       50,
  //       0,
  //       (uri) => {
  //         setToggle(true)
  //         setimage(uri);
  //         const showiMG = URL.createObjectURL(file);
  //         setthumbnail(showiMG);
  //       },
  //       "file",
  //       500,
  //       500
  //     );
  //   }
  // };
  // useEffect(() => {
  //   if(thumbnail==null)
  //   {
  //     setToggle(false)
  //   }
  // }, [thumbnail])

  return (
    <>
      <Form role="form" onSubmit={addbanner}>
        <FormGroup>
          <label className="form-control-label" htmlFor="title">
            Title
          </label>
          <Input
            className="form-control-alternative"
            id="title"
            placeholder="Enter Title"
            type="text"
            value={title}
            onChange={(e) => settitle(e.target.value)}
            required
          />
        </FormGroup>
        {editstatus ? (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
            onClick={updatebanner}
          >
            Update Article
          </Button>
        ) : (
          <Button
            className="my-4 btn-block"
            type="submit"
            color="warning"
            onClick={addbanner}
          >
            Add Article
          </Button>
        )}
      </Form>
    </>
  );
};
export default AddArticleNews;
