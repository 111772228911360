import React, { useState, useEffect, useCallback } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Media,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// APi
import Dataservices from "../../services/requestApi";
// core components
import Header from "components/Headers/Header";
import Loader from "components/loader/Loader";
import { Link, useLocation } from "react-router-dom";
function AllDistributorChild() {
  const [AllUser, setAllUser] = useState([]);
  const [ready, setReady] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation().state;
  // getAllUSERS
  const getAllUSERS = useCallback(
    async (e) => {
      try {
        setReady(true);
        const res = await Dataservices.getAllDistributorChild(location?._id);
        if (res.data?.message === "Success") {
          const user = res.data?.data;
          setAllUser(user);
        }
        setReady(false);
      } catch (e) {
        console.log(e);
      }
    },
    [location]
  );
  useEffect(() => {
    getAllUSERS();
  }, [getAllUSERS]);
  return (
    <>
      <Header show={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="9">
                    <h3 className="mb-0">
                      All Distributor of {location?.name}
                    </h3>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="mb-0">
                      <Input
                        className="form-control-alternative border shadow"
                        placeholder="Search Distributor"
                        id="title"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardHeader>
              <Loader show={ready} />
              <CardBody>
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Genealogy</th>
                        <th scope="col" className="text-right">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllUser &&
                        AllUser.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">{index + 1}</th>
                            <th scope="row">{item?.code}</th>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {item.name}
                                  </span>
                                </Media>
                              </Media>
                            </td>
                            <th scope="row">
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {item.email}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>
                              <Media className="align-items-center">
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {item.phone}
                                  </span>
                                </Media>
                              </Media>
                            </td>
                            <td className="text-right">
                              <Link
                                className={`btn btn-outline-success btn-sm`}
                                to={{
                                  pathname: `all_child/${item?._id}`,
                                  state: item,
                                }}
                              >
                                Genealogy
                              </Link>
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    to={{
                                      pathname: `update_distributor/${item?._id}`,
                                      state: item,
                                    }}
                                    tag={Link}
                                  >
                                    <i className="ni ni-settings text-danger" />{" "}
                                    Update Profile
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllDistributorChild;
