import { Image } from "antd";
import React from "react";
import { FormGroup, Input, Button, Form } from "reactstrap";
const AddBrand = ({
  brand,
  setBrand,
  thumbnail,
  iconthumbnail,
  description,
  setdescription,
  handleIcon,
  handleImage,
  addbrand,
  statusupdate,
  updatebrand,
}) => {
  return (
    <>
      <Form role="form">
        <FormGroup>
          <label className="form-control-label" htmlFor="name">
            Enter Brand name
          </label>
          <Input
            className="form-control-alternative"
            id="name"
            placeholder="Brand name"
            type="text"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="image">
            Brand Icon
          </label>
          <Input className="form-control-alternative" id="image" type="file" accept="image/*" onChange={handleIcon} />
          {iconthumbnail && <Image width={200} src={iconthumbnail} className="mt-3" />}
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="icons">
            Brand Fetured Image
          </label>
          <Input className="form-control-alternative" id="icons" type="file" accept="image/*" onChange={handleImage} />
          {thumbnail && <Image width={200} src={thumbnail} className="mt-3" />}
        </FormGroup>
        <FormGroup>
          <label className="form-control-label" htmlFor="desc">
            Description
          </label>

          <Input
            className="form-control-alternative"
            id="desc"
            placeholder="Enter Short Description"
            type="textarea"
            rows="4"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </FormGroup>

        {statusupdate ? (
          <Button className="my-4 btn-block" type="submit" color="primary" onClick={updatebrand}>
            Update Brand
          </Button>
        ) : (
          <Button className="my-4 btn-block" type="submit" color="primary" onClick={addbrand}>
            Add Brand
          </Button>
        )}
      </Form>
    </>
  );
};
export default AddBrand;
