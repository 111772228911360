// reactstrap components
import React, {useState, useEffect} from "react";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col, Container, Row,
} from "reactstrap";
import queryString from "query-string";
import Dataservices from "../../services/requestApi";
import {Redirect} from "react-router-dom";
import {message} from "antd";
import Header from "../../components/Headers/Header";

const ChangePassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // const [isLogedin, setIsLogedin] = React.useState(false);
    const [loadings, setLoadings] = useState(false);
    // useEffect(() => {
    //     const token = sessionStorage.getItem("Authtoken");
    //     if (token == null) {
    //         setIsLogedin(false);
    //     } else if (token) {
    //         setIsLogedin(true);
    //     }
    // }, []);
    const AuthLogin = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            message.error('Your password and confirm password is not matched');
        }
        try {
            setLoadings(true);
            const Data = {
                oldPassword,
                password
            };
            const response = await Dataservices.ChangePassword(oldPassword, password);
            // const token = response.data.token;
            if (response.data.status_code === 200) {
                message.success(response.data.message);
            }else{
                message.error("Admin old password doesn't match");
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoadings(false);
        }
    };
    // if (isLogedin) {
    //     return <Redirect to="/admin/index" />;
    // }
    return (

        <>
            <Header show={false}/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row style={{justifyContent: 'center'}}>
                    <Col lg="5" md="7">
                        <Card className="bg-secondary shadow-lg">

                            <CardBody className="px-lg-5 py-lg-5">
                                {/*<div className="text-muted text-center mt-2 mb-3">*/}
                                {/*    <img*/}
                                {/*        src={require("../../assets/img/brand/logo.png").default}*/}
                                {/*        width="200"*/}
                                {/*        alt="pujyapanditg"*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <Form role="form" onSubmit={AuthLogin}>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="OldPassword"
                                                type="password"
                                                autoComplete="new-password"
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="NewPassword"
                                                type="password"
                                                autoComplete="new-password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="ConfirmPassword"
                                                type="text"
                                                autoComplete="new-password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button
                                            className="my-4 btn-block"
                                            type="submit"
                                            color="warning"
                                            onClick={AuthLogin}
                                            disabled={loadings}
                                        >
                                            {loadings ? "please wait..." : "Submit"}
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
        ;
};

export default ChangePassword;
